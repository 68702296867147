import en from './en';
import it from './it';

/**
 * Inizializza la localizzazione
 *
 * @ignore
 * @param {string} [lng=fallbackLanguage] - Lingua con cui inizializzare i18next. Usa la lingua definita in config come valore di default.
 */
export default {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  }
}
