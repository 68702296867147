import errorCodes from './errorCodes';
import validation from './validation';
import header from './header';

/**
 * @ignore
 * @constant {Object} it - Insieme di namespace per il dizionario in lingua italiana.
 * @property {Object} it.errorCodes namespace per i codici errore
 */
const it = {
  errorCodes,
  validation,
  header,
};

export default it;
