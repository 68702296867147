import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from "prop-types";

export default class ExceptionHandler extends React.Component{

  static get propTypes() {
    return {
      children: PropTypes.node,
    };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    return this.props.children;
  }
}
