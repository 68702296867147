export default {
  title: "Lista codici errore",
  addNewErrorCode: "Aggiungi nuovo codice errore",
  editErrorCode: "Modifica codice errore",
  errorCode: "Codice errore",
  modelCode: "Codice modello",
  modelCodePlaceholder: "Seleziona il codice modello",
  message: "Testo chatbot",
  endings: "Stato uscita",
  endingsPlaceholder: "Seleziona lo stato d'uscita",
  searchPlaceholder: "Ricerca codice errore",
  searchModelPlaceholder: "Ricerca modello",
  createButton:"Crea nuovo codice errore",
  editButton:"Modifica codice errore",
  deleteRowConfirm: "Eliminare il codice errore?",
  deleteOkmessage: 'Codice errore cancellato correttamente',
  editOkmessage:'Codice errore modificato correttamente',
  createOkMessage: 'Codice errore creato correttamente',
}
