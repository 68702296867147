import "@babel/polyfill";
import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { LocaleProvider, Spin, Icon } from 'antd';
import {sentryDSN} from 'config';
import i18next from "i18next";

import 'antd/dist/antd.less';
import '@vidiemme/react-chatbot-backoffice/src/index.scss';
import './index.scss';

import {
  Root as RootComponent,
  Reducers,
  locales,
  Services,
} from '@vidiemme/react-chatbot-backoffice';

/// Ant locales
import itIT from 'antd/lib/locale-provider/it_IT';
import enUS from 'antd/lib/locale-provider/en_US';

import properties from './properties';
import Routes from './routes';
import ProjectLocales from './locales';
import ProjectReducers from './reducers';
import ExceptionHandler from './components/ExceptionHandler';

let locale = navigator.language || navigator.userLanguage;
if( locale.indexOf('it') > -1 ) {
  locale = 'it';
} else {
  locale = 'en';
}

Sentry.init({
  dsn: sentryDSN,
  // Disabilito gli eventi se il DSN non è definito
  beforeSend: sentryDSN === '' ? () => null : undefined,
});

const bootstrap = () => locales(locale, ProjectLocales)
  .then(Services.AuthenticationService.isAuthenticated)
  .then((isAuthenticated) => {
    return Services.AuthenticationService.getUserPermissions()
      .then((permissions) => {
        const store = createStore(combineReducers({ ...Reducers, ...ProjectReducers }), {
          authentication: {
            authenticated: isAuthenticated,
            permissions
          },
          productProperties: properties,
        });
        const next = store.dispatch;
        store.dispatch = function dispatchAndLog(action) {
          //console.log('dispatching', action.type); // eslint-disable-line
          try{
            Sentry.addBreadcrumb({
              category: "USER_ACTION",
              message: action.type,
              data: action,
            });
            return next(action)
          } catch(e) {
            Sentry.captureException(e);
          }
        };
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        Spin.setDefaultIndicator(antIcon);
        render(
          <Provider store={store}>
            <LocaleProvider locale={locale === "it" ? itIT : enUS} >
              <ExceptionHandler>
                <RootComponent >
                  <Routes />
                </RootComponent>
              </ExceptionHandler>
            </LocaleProvider>
          </Provider>,
          document.getElementById('app'))
      })
  });

if (window.cordova) {
  document.addEventListener('deviceready', bootstrap, false);
} else {
  window.addEventListener('load', bootstrap, false);
}
