export default {
  header: {
    logo: require('./assets/images/lv8-logo.png'),
    menu: () => []
  },
  login: {
    logo: require('./assets/images/lv8-logo.png'),
  },
  entityManagerEnabled: true,
  topicCrudEnabled: true
  }

