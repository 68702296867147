/**
 * Base URL utilizzato dai repository per effettuare le chiamate al server
 *
 * @ignore
 * @constant {string} baseURL
 */
export const baseURL = '/api';

/**
 * Header token autenticazione
 *
 * @ignore
 * @constant {string}
 */
export const authenticationTokenHeader = 'X-Authentication-Token';

/**
 * Prefisso utilizzato per le chiavi salvate in localStorage
 *
 * @ignore
 * @constant {string} localStoragePrefix
 */
export const localStoragePrefix = 'boilerplate_react';

/**
 * Lingua di fallback
 *
 * @ignore
 * @constant {string} fallbackLanguage
 */
export const fallbackLanguage = 'it';

/**
 * Lista di ID dei dialoghi presenti nel progetto
 * @ignore
 * @constant {array} dialogs
 */
export const dialogs = [1];

/**
 * DSN per sentry
 * @constant {string} sentryDSN
 */
export const sentryDSN = "";
