/**
 * @ignore
 * @constant {Object} header - Namespace per il dizionario italiano della componente Header.
 * @property {Object} header.menu - Namespace per il menu.
 * @property {string} header.menu.manager - Testo item menu manager
 * @property {string} header.menu.conversations - Testo item menu conversations
 * @property {string} header.menu.setting - Testo item menu setting
 * @property {string} header.menu.training - Testo item submenu training
 * @property {string} header.menu.favorite - Testo item submenu favorite
 * @property {string} header.menu.archive - Testo item submenu archive
 * @property {string} header.signout - Bottone di signout.
 */
const header = {
  menu: {
    errorCode: 'Codici Errore',
  },
};

export default header;
