import React, { Fragment } from 'react';
//import { Route } from 'react-router-dom';
// import {Middlewares} from 'react-chatbot-backoffice';
//
// import ErrorCodes from '../containers/ErrorCodes/List';
//
// import PermissionsNames from '../constants/permissions'

/**
* Componente Routes.
* Restituisce le route per la gestione della navigazione.
*/
class Routes extends React.Component {
  render() {
    return (
      <Fragment>
      </Fragment>
    );
  }
}

export default Routes;
